import { Json } from "polycraft/src/types";
import { u } from "polycraft/src/ui/text";
import { FunctionalComponent, h } from "preact";
import { Call } from "../../components/call";
import { Chatting } from "../../components/chatting";
import { SendEmail } from "../../components/send-email";
import { useHelp } from "./useHelp";

const Tabs: Json = {
  call: Call,
  chat: Chatting,
  email: SendEmail,
};

const Help: FunctionalComponent<{}> = () => {
  const {
    setTab,
    state: { tab, call, chat, email },
  } = useHelp();

  const C = Tabs[tab];

  return (
    <div class="flex column p-16 justify-start items-center fill">
      <div class="flex justify-center items-center mb-16">
        <a
          onClick={(): void => setTab("email")}
          class={`mr-16 mb-8 flex column items-center ${email}`}
        >
          <i class="material-icons-round md-24" style="font-size: 48px">
            email
          </i>
          {u`Send email`}
        </a>
        <a
          onClick={(): void => setTab("chat")}
          class={`mx-16 mb-8 flex column items-center ${chat}`}
        >
          <i class="material-icons-round md-24" style="font-size: 48px">
            chat
          </i>
          {u`Chatting`}
        </a>
        <a
          onClick={(): void => setTab("call")}
          class={`ml-16 mb-8 flex column items-center ${call}`}
        >
          <i class="material-icons-round md-24" style="font-size: 48px">
            call
          </i>
          {u`Call us`}
        </a>
      </div>
      <C />
    </div>
  );
};

export default Help;
