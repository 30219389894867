import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionalComponent, h } from "preact";
import { BankDetail } from "../capture-process/useCaptureProcess";
import { Lock } from "../lock";
import { PinInput } from "../pin-input";
import { useCaptureConfirmDialog } from "./useCaptureConfirmDialog";

interface Props {
  onClose: () => void;
  bankDetail: BankDetail;
  onConfirm: () => void;
}

export const CaptureDialogConfirm: FunctionalComponent<Props> = ({
  bankDetail,
  onClose,
  onConfirm,
}: Props) => {
  const { onInternalConfirm, onSubmit, button } = useCaptureConfirmDialog(
    bankDetail,
    onConfirm
  );

  return (
    <Dialog
      onClose={onClose}
      close={u("Back")}
      onConfirm={onInternalConfirm}
      title={u("Capture")}
      class={"pc-swal__input"}
      confirm={u("Confirm")}
      show={true}
    >
      <form class="mt-16" onSubmit={onSubmit}>
        <div class="flex items-center column justify-center">
          <Lock />
          <p class="mb-10 font-color mt-16">
            {u("Please enter your 6 digit pin")}
          </p>
          <p style="display: none"></p>
        </div>
        <PinInput />
        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
