import "@picocss/pico";
import "polycraft/src/style/theme/admin.css";
import "polycraft/src/style/theme/full.css";
import { Router } from "polycraft/src/util/router";
import { h, render } from "preact";
import "../css/main.css";
import App from "../preact/components/app";
import "../style/custom.css";

declare const PREACT_APP_BASE_URL: string;

Router.register(PREACT_APP_BASE_URL);

const app = document.getElementById("root");
if (app) {
  render(<App />, app);
}
