import { SimpleListItem } from "polycraft/src/ui/simple-list-item";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionalComponent, h } from "preact";
import { CaptureGoldProgress } from "../../components/capture-gold-progress";
import { Circle } from "../../components/circle";
import { RawSquare } from "../../components/raw-square";
import { Square } from "../../components/square";
import { useVault } from "./useVault";

// Add style from polycraft
Circle;

export const Vault: FunctionalComponent = () => {
  const {
    onCapture,
    onCaptureClose,
    state: { capture, vault },
  } = useVault();

  const items = [
    {
      description: u("Total commissions"),
      metaText: vault?.CEZG?.toString() ?? "-",
      name: "EZG",
    },
  ];

  return (
    <div class="flex column p-16 column fill">
      <RawSquare title={u("Principal")}>
        <Fragment>
          <div class={"flex justify-around p-16"}>
            <div
              class={`flex justify-center items-center p-16 box-shadow text-center ui-circle ui-circle-secondary`}
            >
              <div class="flex items-center">
                <p style={"font-size:42px"}>
                  {vault?.IMOM} {u("MOM")}{" "}
                </p>
              </div>
            </div>

            <div
              class={`flex justify-center items-center p-16 box-shadow text-center ui-circle ui-circle-primary`}
            >
              <div class="flex items-center">
                <p style={"font-size:42px"}>
                  {vault?.CEZG} {u("EZG")}
                </p>
              </div>
            </div>
          </div>
        </Fragment>
      </RawSquare>
      <div class="mt-16" />
      <RawSquare title={u("Main dashboard")}>
        <div class="flex column">
          <div class="flex fill">
            <SimpleListItem
              text="MOM"
              secondaryText="Momentum token"
              metaText="1 MOM = 10000 EZG"
              class="fill border-right border-bottom"
            />
            <SimpleListItem
              text="MOMYIELD"
              secondaryText="EZG / MOM"
              metaText={`${vault?.MOMYIELD ?? ""}`}
              class="fill border-bottom"
            />
          </div>
          <div class="flex fill">
            <SimpleListItem
              text="CMOM"
              secondaryText="Current MOM"
              metaText={`${vault?.CMOM ?? ""}`}
              class="fill border-right border-bottom"
            />
            <SimpleListItem
              text="CEZG"
              secondaryText="Current EZG"
              metaText={`${vault?.CEZG ?? ""}`}
              class="fill border-bottom"
            />
          </div>
          <div class="flex fill">
            <SimpleListItem
              text="BUYMOM"
              secondaryText="Bought MOM"
              metaText={`${vault?.BUYMOM ?? ""}`}
              class="fill border-right border-bottom"
            />
            <SimpleListItem
              text="SELLMON"
              secondaryText="Sold MOM"
              metaText={`${vault?.SELLMON ?? ""}`}
              class="fill border-bottom"
            />
          </div>
          <div class="flex fill">
            <SimpleListItem
              text="COMEZG"
              secondaryText="EZG commissions"
              metaText={`${vault?.COMEZG ?? ""}`}
              class="fill border-right"
            />
            <SimpleListItem
              text="ROI"
              secondaryText="Return On Investment"
              metaText={`${vault?.ROI ?? ""}`}
              class="fill"
            />
          </div>
        </div>
      </RawSquare>

      <div class="flex justify-end mt-16">
        <a class="button px-16 py-8 flex items-center" onClick={onCapture}>
          <span class="material-icons-round mr-8">file_download</span>
          {u`Capture`}
        </a>
      </div>

      <Square
        items={items}
        onClick={onCapture}
        title={u("Commission")}
        class={"mt-16 radius commission-square"}
      />

      <CaptureGoldProgress
        open={capture}
        onClose={onCaptureClose}
        vault={vault}
      />
    </div>
  );
};
