import { FormJson } from "polycraft/src/components/form-json";
import { u } from "polycraft/src/ui/text";
import { freset } from "polycraft/src/util/form";
import { FunctionalComponent, h } from "preact";

export const SendEmail: FunctionalComponent = () => {
  const onSuccess = async (): Promise<void> => {
    window.alert(u("Your message has been sent successfully."));
    freset();
  };

  return (
    <div style="min-width: 500px; max-width: 768px;">
      <FormJson url={"/message"} onSuccess={onSuccess} csrf={true}>
        <label id="email">{u("Email")}</label>
        <input name="EMAIL" type="email" id="email" required />

        <label id="topic">{u("Topic")}</label>
        <select name="TOPIC" id="topic">
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
        </select>

        <label id="message">{u("Message")}</label>
        <textarea name="TEXT" rows={4} style="resize: none" required />

        <button>{u("Send")}</button>
      </FormJson>
    </div>
  );
};
