import { fetchy } from "polycraft/src/util/fetchy";
import { FunctionalComponent, h } from "preact";
import "./index.css";
import { useDashboardHeader } from "./useDashboardHeader";

h;

interface Props {
  url: string;
}

export const DashboardHeader: FunctionalComponent<Props> = ({ url }: Props) => {
  const { security, onClick, onDarkModeClick } = useDashboardHeader();

  if (url === "/" || url === "/browser-support") {
    return <span />;
  }

  const src = `assets/icons/${security}.svg`;
  const Security = security ? (
    <img src={src} alt="Security" width={32} class={"mr-16"} loading="lazy" />
  ) : (
    <span style="height: 32px; width: 32px" class="mr-16" />
  );

  const logout = async () => {
    await fetchy("/logout", { body: "{}" });

    window.history.replaceState({}, "", "/login.html");
  };
  /*
 <span
            class="material-icons-round md-32 hover mr-16"
            onClick={onDarkModeClick}
          >
            dark_mode
          </span>*/
  return (
    <header class={"header"}>
      <div class={"flex justify-between relative p-16 transition shadow"}>
        <div />
        <div class="flex items-center">
          {Security}

          <span
            class="material-icons-round md-32 hover mr-16"
            onClick={onClick}
          >
            refresh
          </span>
          <i class="material-icons-round md-32 hover" onClick={logout}>
            logout
          </i>
        </div>
      </div>
    </header>
  );
};
