import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { h } from "preact";
import { ConvertDialogProps, useConvertDialog } from "./useConvertDialog";

export const ConvertDialog = ({
  commission,
  onClose,
  onConfirm,
}: ConvertDialogProps) => {
  const {
    onChange,
    onSubmit,
    onInternalConfirm,
    button,
    state: { filtered, currency, rate },
  } = useConvertDialog(commission, onConfirm, onClose);

  return (
    <Dialog
      onClose={onClose}
      onConfirm={onInternalConfirm}
      title={u("Convert")}
      class={"pc-swal__input"}
      confirm={u("Confirm")}
      show={true}
    >
      <form onSubmit={onSubmit} class="mt-16">
        <label for="amount">{u("Balance")}</label>
        <input
          name="SAMOUNT"
          value={commission?.amount}
          readonly={true}
          type="number"
          id="amount"
        />

        <label for="currencies">{u("Currencies")}</label>
        <select
          name="CURRENCYD"
          id="currencies"
          onChange={onChange}
          required={true}
        >
          {filtered.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>

        <label for="amountd">{u("Destination amount")}</label>
        <input
          name="AMOUNTD"
          value={rate}
          readonly={true}
          type="number"
          id="amountd"
        />

        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
