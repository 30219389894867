import { Json } from "polycraft/src/types";
import { useCallback, useState } from "preact/hooks";
import { fetchy } from "polycraft/src/util/fetchy";

type Rate = any;

export function useRate(
  traty: "CONVERT" | "CAPTURE"
): [
  Rate | undefined,
  (
    currencys: string,
    currencyd: string,
    amount: number,
    extra?: Json
  ) => Promise<Rate>
] {
  const [rate, setRate] = useState<Rate | undefined>(undefined);

  const refreshRate = useCallback(
    async (
      currencys: string,
      currencyd: string,
      amount: number,
      extra: Json = {}
    ) => {
      if (!currencys || !currencyd || amount === undefined) {
        return;
      }

      const body = JSON.stringify({
        ...extra,
        CURRENCYS: currencys,
        CURRENCYD: currencyd,
        SCURRENCY: currencys,
        TRATY: traty,
        SAMOUNT: amount,
      });

      const r = await fetchy("/rate", { body });

      setRate(r);

      return r;
    },
    []
  );

  return [rate, refreshRate];
}
