import { u } from "polycraft/src/ui/text";
import { freset } from "polycraft/src/util/form";
import { useCallback } from "preact/hooks";

export function useChatting() {
  const onSuccess = useCallback(async (): Promise<void> => {
    window.alert(u("Your message has been sent successfully."));
    freset();
  }, []);

  return {
    onSuccess,
  };
}
