import { FormJson } from "polycraft/src/components/form-json";
import { u } from "polycraft/src/ui/text";
import { FunctionalComponent, h } from "preact";
import { useChatting } from "./useChatting";

export const Chatting: FunctionalComponent = () => {
  const { onSuccess } = useChatting();

  return (
    <div class="flex column" style="min-width: 500px; max-width: 768px;">
      <FormJson url={"/message"} onSuccess={onSuccess} csrf={true}>
        <label id="email">{u("Email")}</label>
        <input name="EMAIL" type="email" id="email" required />

        <label id="message">{u("Message")}</label>
        <textarea name="TEXT" rows={4} style="resize: none" required />

        <button>{u("Send")}</button>
      </FormJson>

      <div>
        <h2 class={"uppercase primary mb-8"}>{u`Live chat`}</h2>
        <p>{u`Our chat working hours are listed in EST below:`}</p>
        <ul>
          <li>► {u`Mon: 2:00 AM to midnight`}</li>
          <li>► {u`Tue-Thu: 24-hours`}</li>
          <li>► {u`Fri: midnight to 6:00`}</li>
        </ul>
      </div>
    </div>
  );
};
