import { Fragment, h } from "preact";
import { VaultSchema } from "../../types";
import { CaptureGoldDialog } from "../capture-gold-dialog";
import { CaptureGoldDialogConfirm } from "../capture-gold-dialog-confirm";
import {
  CaptureGoldStep,
  useCaptureGoldProgress,
} from "./useCaptureGoldProgress";

interface CaptureGoldProgressProps {
  vault: VaultSchema | undefined;
  open: boolean;
  onClose: () => void;
}

export const CaptureGoldProgress = ({
  vault,
  open,
  onClose,
}: CaptureGoldProgressProps) => {
  const { state, onCaptureConfirm, onBack, onInternalClose } =
    useCaptureGoldProgress(onClose);

  if (!open) {
    return <Fragment></Fragment>;
  }

  if (state.step === CaptureGoldStep.Confirm && state.values) {
    return (
      <CaptureGoldDialogConfirm
        onClose={onBack}
        values={state.values}
        onConfirm={onInternalClose}
      />
    );
  }

  return (
    <CaptureGoldDialog
      onClose={onInternalClose}
      vault={vault}
      onConfirm={onCaptureConfirm}
    />
  );
};
