import { Fragment, FunctionalComponent, h } from "preact";
import { CaptureDialog } from "../capture-dialog";
import { CaptureDialogConfirm } from "../capture-dialog-confirm";
import { CaptureDialogReview } from "../capture-dialog-review";
import { CaptureStep, useCaptureProcess } from "./useCaptureProcess";

interface Props {
  onClose: () => void;
  open: boolean;
}

export const CaptureProcess: FunctionalComponent<Props> = ({
  open,
  onClose,
}: Props) => {
  const {
    onCaptureStep,
    onRequest,
    onBackToReview,
    state: { details, ...state },
    onInternalClose,
    onConfirmation,
  } = useCaptureProcess(onClose);

  if (!open) {
    return <Fragment></Fragment>;
  }

  if (state.step === CaptureStep.Confirm && details) {
    return (
      <CaptureDialogConfirm
        bankDetail={details}
        onClose={onBackToReview}
        onConfirm={onInternalClose}
      />
    );
  }

  if (state.step === CaptureStep.Review && details) {
    return (
      <CaptureDialogReview
        bankDetail={details}
        onClose={onCaptureStep}
        onConfirm={onConfirmation}
      />
    );
  }

  return <CaptureDialog onClose={onInternalClose} onConfirm={onRequest} />;
};
