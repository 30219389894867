import { h } from "preact";
import { avatars, useAvatars } from "./useAvatars";

interface Props {
  onSelected: (s: string) => void;
}

export const Avatars = ({ onSelected }: Props) => {
  const { selected, onInternalSelected } = useAvatars(onSelected);

  return (
    <div
      class="flex wrap my-16 justify-center mx-auto"
      style={{ maxWidth: "272px" }}
    >
      {avatars.map((a) => {
        const css = selected === a ? "border-primary" : "border-transparent";
        return (
          <div
            class={`flex items-center hover ${css}`}
            onClick={() => onInternalSelected(a)}
          >
            <img key={a} src={`assets/icons/${a}.svg`} alt={a} />
          </div>
        );
      })}
    </div>
  );
};
