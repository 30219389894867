import { EFormEvent } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";
import { useRate } from "../../hooks/useRate";
import { CaptureGoldValues } from "../capture-gold-progress/useCaptureGoldProgress";

interface ConfirmValues extends Record<string, string | File | number> {
  PIN: string;
}

export interface CaptureGoldDialogConfirmProps {
  onClose: () => void;
  onConfirm: () => void;
  values: CaptureGoldValues;
}

export function useCaptureGoldDialogConfirm({
  values,
  onClose,
  onConfirm,
}: CaptureGoldDialogConfirmProps) {
  const [, setRate] = useRate("CAPTURE");

  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const { PIN } = await check<ConfirmValues>(e);
      const { END, SAMOUNT, SCURRENCY } = values;

      await setRate(SCURRENCY, SCURRENCY, SAMOUNT, {});

      const params = {
        END,
        SAMOUNT,
        SCURRENCY,
        CURRENCYS: SCURRENCY,
        CURRENCYD: SCURRENCY,
        PIN,
      };

      await fetchy("/capture", { body: JSON.stringify(params) });

      window.dispatchEvent(new CustomEvent("cashpoolrefresh"));

      onConfirm();
    },
    [onConfirm, values, onClose]
  );

  return {
    onInternalConfirm,
    onSubmit,
    button,
  };
}
