import { EFormEvent, ESelectEvent } from "polycraft/src/types";
import { check } from "polycraft/src/util/form";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import { useBanks } from "../../hooks/useBanks";
import { useCommissions } from "../../hooks/useComissions";
import { currenciesOptions, Currency } from "../../types";

interface Option {
  label: string;
  value: string;
}

interface State {
  currency: string;
  max: number;
  options: Option[];
}

export interface CaptureValues extends Record<string, string | File | number> {
  BANK: string;
  AMOUNT: string;
  CURRENCY: string;
}

export function useCaptureDialog(onConfirm: (values: CaptureValues) => void) {
  const banks = useBanks();
  const { commissions } = useCommissions();

  const [state, setState] = useState({
    currency: currenciesOptions[0].value,
    options: banks?.map((b) => ({ label: b.DEVISE, value: b.ID })),
    max: commissions?.[banks?.[0]?.DEVISE ?? ""],
  });

  const button = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const o = banks?.map((b) => ({ label: b.DEVISE, value: b.ID }));
    setState((s) => ({ ...s, options: o }));
  }, [banks, commissions]);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const values = await check<CaptureValues>(e);
      onConfirm(values);
    },
    [onConfirm]
  );

  const onCurrencyChange = useCallback(
    (e: ESelectEvent) => {
      const c = e.currentTarget.value as Currency;
      const m = commissions?.[c];
      setState((s) => ({ ...s, currency: c, max: m }));
    },
    [commissions]
  );

  return {
    onSubmit,
    onInternalConfirm,
    onCurrencyChange,
    button,
    state,
  };
}
