import { u } from "polycraft/src/ui/text";
import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";
import { CaptureProcess } from "../../components/capture-process";
import { CommissionSquare } from "../../components/commission-square";

const Currencies: FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  return (
    <div class="flex p-16 fill column fill">
      <div class="flex justify-end">
        <a
          class="button mb-16 px-16 py-8 flex items-center"
          onClick={() => setOpen(true)}
        >
          <span class="material-icons-round mr-8">file_download</span>
          <span>{u("Capture")}</span>
        </a>
      </div>
      <CaptureProcess open={open} onClose={() => setOpen(false)} />
      <CommissionSquare length={undefined} class="currencies" />
    </div>
  );
};

export default Currencies;
