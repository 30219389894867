import { Dialog } from "polycraft/src/components/dialog";
import { List } from "polycraft/src/ui/list";
import { SimpleListItem } from "polycraft/src/ui/simple-list-item";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionComponent, h } from "preact";
import { BankDetail } from "../capture-process/useCaptureProcess";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  bankDetail: BankDetail;
}

export const CaptureDialogReview: FunctionComponent<Props> = ({
  onClose,
  onConfirm,
  bankDetail,
}: Props) => {
  const {
    AMOUNT,
    SAMOUNT,
    SCURRENCY,
    FEE,
    CURRENCYD,
    RATESD,
    ID: _,
    ...rest
  } = bankDetail;

  const keys = Object.keys(rest);

  const Details = keys.map((key: string) => (
    <SimpleListItem text={key} metaText={rest[key].toString()} />
  ));

  return (
    <Dialog
      onClose={onClose}
      onConfirm={onConfirm}
      close={u("Back")}
      title={u("Capture")}
      confirm={u("Confirm")}
      show={true}
      class={"nospace"}
    >
      <div class="flex">
        <div class={"flex column w-100 pc-list__extendable"}>
          <List class="">
            <SimpleListItem
              text={u`Amount`}
              metaText={`${AMOUNT} ${SCURRENCY}`}
            />
            <SimpleListItem text={u`Fee`} metaText={`${FEE} ${SCURRENCY}`} />
            <SimpleListItem
              text={u`Amount converted`}
              metaText={`${SAMOUNT} ${SCURRENCY}`}
            />
            <SimpleListItem
              text={u`Rate`}
              metaText={`1 ${SCURRENCY} = ${RATESD} ${CURRENCYD}`}
            />
            {Details}
          </List>
        </div>
      </div>
    </Dialog>
  );
};
