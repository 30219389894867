import { fetchy } from "polycraft/src/util/fetchy";
import { useCallback, useEffect, useState } from "preact/hooks";
import { Commissions } from "../../types";

let c: Commissions | undefined = undefined;
let loaded = 0;
const MAX_ITEMS = 50;

interface State {
  commissions: Commissions | undefined;
  hasMore: boolean;
}

export function useCommissions() {
  const [state, setState] = useState<State>({
    commissions: c,
    hasMore: !c ? false : Object.keys(c).length >= MAX_ITEMS,
  });

  useEffect(() => {
    async function init() {
      c = await fetchy(
        "/currencies",
        { method: "GET" },
        { showProgress: false }
      );
      setState({
        commissions: c,
        hasMore: !c ? false : Object.keys(c).length >= MAX_ITEMS,
      });

      if (c) {
        loaded = Object.keys(c).length;
      }
    }

    if (!c) {
      init();
    }

    window.addEventListener("cashpoolrefresh", init);
    return () => window.removeEventListener("cashpoolrefresh", init);
  }, []);

  const onMore = useCallback(async () => {
    const json = await fetchy("/currencies", {
      body: JSON.stringify({ start: loaded, stop: loaded + MAX_ITEMS }),
      method: "POST",
    });
    setState({
      commissions: json,
      hasMore: !json ? false : Object.keys(json).length >= MAX_ITEMS,
    });
  }, []);

  return { commissions: state.commissions, hasMore: state.hasMore, onMore };
}
