import { EFormEvent } from "polycraft/src/types";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";
import { CaptureGoldValues } from "../capture-gold-progress/useCaptureGoldProgress";

export function useCaptureGoldDialog(
  onClose: () => void,
  onConfirm: (values: CaptureGoldValues) => void
) {
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const values = await check<CaptureGoldValues>(e);
      onConfirm({ ...values, SCURRENCY: "EZG" });
    },
    [onConfirm]
  );

  return {
    button,
    onSubmit,
    onInternalConfirm,
  };
}
