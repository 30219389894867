import { EFormEvent } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";

interface ConfirmValues extends Record<string, string | File | number> {
  PIN: string;
}

export interface ApexDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  ORDERID: string;
}

export function useApexCancelDialogConfirm({
  ORDERID,
  onConfirm,
}: ApexDialogProps) {
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const { PIN } = await check<ConfirmValues>(e);

      if (!ORDERID) {
        return;
      }

      const body = JSON.stringify({
        ORDERID,
        PIN,
      });

      await fetchy("/apex-cancel", { body });

      window.dispatchEvent(new CustomEvent("apexcancel", { detail: ORDERID }));

      onConfirm();
    },
    [, onConfirm]
  );

  return {
    onInternalConfirm,
    onSubmit,
    button,
  };
}
