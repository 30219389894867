import { Dialog } from "polycraft/src/components/dialog";
import { FormJson } from "polycraft/src/components/form-json";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionComponent, h } from "preact";
import { Avatars } from "../../components/avatars";
import { useLogin } from "./useLogin";

const Login: FunctionComponent = () => {
  const {
    onSecurityImage,
    onSuccess,
    onSelected,
    state: { open },
  } = useLogin();

  return (
    <div class="flex fill">
      <aside class="flex column fill justify-center items-center aside">
        <img
          src={"assets/icons/singleton-logo.svg"}
          alt={u("Logo")}
          class={"login-logo"}
          width={175}
          height={108}
        />
        <p class="mb-0 mt-16">
          {u("Welcome to the unique financial dashboard")}
        </p>
      </aside>
      <main class="flex column justify-center fill items-center p-16">
        <FormJson csrf={true} onSuccess={onSuccess} url={"/login"}>
          <label for="login">Login</label>
          <input name="CPRID" required={true} id="login" />

          <label for="password">Password</label>
          <input name="PWD" required={true} id="password" type="password" />

          <button type="submit">Login</button>
        </FormJson>
      </main>

      <Dialog
        show={open}
        title={u("Choose your security image")}
        confirm={u("Choose")}
        close={""}
        onConfirm={onSecurityImage}
      >
        <Avatars onSelected={onSelected} />
      </Dialog>
    </div>
  );
};

export default Login;
