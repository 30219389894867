import { useBanks } from "../../hooks/useBanks";
import { Bank } from "../../types";

function transform(bank: Bank) {
  return {
    name: bank.IBAN ?? bank.ACCTN ?? "",
    description: bank.BANKN ?? "",
    metaText: bank?.DEVISE,
  };
}

export function useBankSquare() {
  const banks = useBanks();

  return {
    items: banks?.map(transform),
  };
}
