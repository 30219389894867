import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { h } from "preact";
import { Lock } from "../lock";
import { PinInput } from "../pin-input";
import {
  ConvertDialogProps,
  useConvertDialogConfirm,
} from "./useConvertDialogConfirm";

export const ConvertDialogConfirm = ({
  values,
  commission,
  onClose,
  onConfirm,
}: ConvertDialogProps) => {
  const { onInternalConfirm, onSubmit, button } = useConvertDialogConfirm({
    values,
    commission,
    onClose,
    onConfirm,
  });

  if (commission === undefined) {
    return <span />;
  }

  return (
    <Dialog
      close={u("Back")}
      onClose={onClose}
      onConfirm={onInternalConfirm}
      title={u("Convert")}
      class={"pc-swal__input"}
      confirm={u("Confirm")}
      show={true}
    >
      <form class="mt-16" onSubmit={onSubmit}>
        <div class="flex items-center column justify-center">
          <Lock />
          <p class="mb-10 font-color mt-16">
            {u("Please enter your 6 digit pin")}
          </p>
          <p style="display: none"></p>
        </div>
        <PinInput />
        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
