import { List } from "polycraft/src/ui/list";
import { FunctionComponent, h } from "preact";
import "./index.css";

const data = [
  {
    name: "My Money",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "Payments",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "Produts and Services",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "All about EZY",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "Disputes and Claims",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "Merchants",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
  {
    name: "My account",
    questions: [
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
      {
        answer:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
        question: "What are the fees for EZY accounts?",
      },
    ],
  },
];

const Faq: FunctionComponent = () => {
  return (
    <div class="flex wrap column fill p-16">
      {data.map((d, i) => {
        return (
          <div key={`d${i}`} class={"mb-16 p-0 faq"}>
            <p class={"m-0 p-16 faq-header radius bold"}>{d.name}</p>
            <List class="faq__list">
              {d.questions.map((q, j) => (
                <details key={j} class="p-16 mb-0">
                  <summary>{q.question}</summary>
                  <p class="mb-0">{q.answer}</p>
                </details>
              ))}
            </List>
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
