import { u } from "polycraft/src/ui/text";
import { useCallback, useEffect, useState } from "preact/hooks";
import { AsideItems } from "../../types";

export interface AsideProps {
  url: string;
  items: AsideItems[];
}

export function useAside({ url, items }: AsideProps) {
  const [selected, setSelected] = useState(-1);
  const [labels] = useState({
    "home.html": u("menu_home"),
    "currencies.html": u("menu_currencies"),
    "vault.html": u("menu_vault"),
    "apex.html": u("menu_apex"),
    "history.html": u("menu_history"),
    "help.html": u("menu_help"),
    "faq.html": u("menu_faq"),
    "logout.html": u("menu_logout"),
  });

  useEffect(() => {
    const i = items.findIndex((item: AsideItems) => url.includes(item.name));
    setSelected(i);
  }, [url]);

  const onClick = useCallback(
    async (e: Event, index: number) => {
      e.preventDefault();

      setSelected(index);

      window.scrollTo(0, 0);

      history.pushState({}, "", "/" + items[index].name);
    },
    [items]
  );

  const generateCss = useCallback(
    (index: number) => {
      return selected === index || (selected === -1 && index === 0)
        ? "active "
        : "";
    },
    [selected]
  );

  return {
    onClick,
    generateCss,
    labels,
  };
}
