import { u } from "polycraft/src/ui/text";
import { useCallback, useState } from "preact/hooks";
import { useCommissions } from "../../hooks/useComissions";
import { Commission, Currency, SquareData } from "../../types";

export function useCommissionSquare(length: number) {
  const { commissions, hasMore, onMore } = useCommissions();
  const [commission, setCommission] = useState<Commission | undefined>(
    undefined
  );

  const transform = useCallback(
    (key: string) => {
      if (commissions === undefined) {
        return {
          description: "-",
          metaText: u("Convert"),
          name: key,
        };
      }

      return {
        description: commissions[key],
        metaText: u("Convert"),
        name: key,
      };
    },
    [commissions]
  );

  const onClick = useCallback((i: SquareData): void => {
    setCommission({
      amount: parseInt(i.description, 10),
      currency: i.name as Currency,
    });
  }, []);

  const onClose = useCallback(() => {
    setCommission(undefined);
  }, []);

  const goToCurrencies = () => {
    history.pushState({}, "", "/currencies.html");
  };

  return {
    onSeeAll: length ? goToCurrencies : undefined,
    onClick,
    onClose,
    onMore,
    state: {
      hasMore,
      items:
        commissions === undefined
          ? commissions
          : Object.keys(commissions).map(transform),
      commission,
    },
  };
}
