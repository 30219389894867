import { useCallback, useState } from "preact/hooks";
import { ConvertValues } from "../convert-dialog/useConvertDialog";

export enum ConvertStep {
  Convert = 0,
  Confirm = 1,
}

interface State {
  values: ConvertValues | null;
  step: ConvertStep;
}

export function useConvertProgress(onClose: () => void) {
  const [state, setState] = useState<State>({
    step: ConvertStep.Convert,
    values: null,
  });

  const onConvertConfirm = (values: ConvertValues) => {
    setState({ step: ConvertStep.Confirm, values });
  };

  const onBack = () => {
    setState({ step: ConvertStep.Convert, values: null });
  };

  const onInternalClose = useCallback(() => {
    document.documentElement.style.overflowY = "auto";
    setState({ step: ConvertStep.Convert, values: null });
    onClose();
  }, [onClose]);

  return {
    onBack,
    onInternalClose,
    onConvertConfirm,
    state,
  };
}
