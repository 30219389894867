import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionalComponent, h } from "preact";
import { currenciesOptions } from "../../types";
import { CaptureValues, useCaptureDialog } from "./useCaptureDialog";

interface Props {
  onClose: () => void;
  onConfirm: (values: CaptureValues) => void;
}

export const CaptureDialog: FunctionalComponent<Props> = ({
  onClose,
  onConfirm,
}: Props) => {
  const { onInternalConfirm, onCurrencyChange, state, onSubmit, button } =
    useCaptureDialog(onConfirm);

  return (
    <Dialog
      show={true}
      onClose={onClose}
      onConfirm={onInternalConfirm}
      title={u("Capture")}
      confirm={u("Confirm")}
    >
      <form class="mt-16" onSubmit={onSubmit}>
        <label for="currencies">{u("Currencies")}</label>
        <select
          id="currencies"
          name="CURRENCY"
          required={true}
          value={state.currency}
          onChange={onCurrencyChange}
        >
          {currenciesOptions.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>

        <label for="amount">{u("Amount")}</label>
        <input
          type="number"
          name="AMOUNT"
          id="amount"
          max={state.max}
          required={true}
        />

        <label for="banks">{u("Bank account")}</label>
        <select
          id="banks"
          name="BANK"
          required={true}
          value={state.options?.[0] ? state.options[0].value : ""}
        >
          {state.options?.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>

        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
