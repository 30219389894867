import { Card } from "polycraft/src/ui/card";
import { CircularProgress } from "polycraft/src/ui/circular-progress";
import { List } from "polycraft/src/ui/list";
import { SimpleListItem } from "polycraft/src/ui/simple-list-item";
import { u } from "polycraft/src/ui/text";
import { FunctionalComponent, h, JSX } from "preact";
import { useCallback } from "preact/hooks";
import { SquareData } from "../../types";

interface Props {
  title: string;
  items: SquareData[] | undefined;
  more?: string;
  moreIcon?: string;
  onClick?: (i: SquareData) => void;
  placeholder?: string;
  onSeeAll?: () => void;
  children?: JSX.Element;
  class?: string;
  length?: number;
}

export const Square: FunctionalComponent<Props> = ({
  title,
  items,
  onClick,
  onSeeAll,
  placeholder,
  children,
  class: className,
  length = 99999999,
}: Props) => {
  const onInternalClick = useCallback(
    (i: SquareData) => {
      if (onClick === undefined) {
        return;
      }
      onClick(i);
    },
    [onClick]
  );

  const border = items === undefined || items.length < 4 ? "border-top" : "";

  if (items === undefined) {
    return (
      <Card class={className}>
        <div class={`square__header radius`}>
          <p class={"p-16 m-0 bold"}>{title}</p>
        </div>
        <div class="flex column justify-center fill relative">
          <CircularProgress class={"full-absolute"} />
        </div>
      </Card>
    );
  }

  const ListJSX = items !== undefined && items.length > 0 && (
    <List>
      {items.slice(0, length).map((i, index) => {
        return (
          <SimpleListItem
            disabled={i.disabled}
            key={index}
            text={i.name}
            secondaryText={i.description}
            metaIcon={i.icon}
            onClick={(): void => onInternalClick(i)}
            metaText={i.metaText}
            class={"border-b-1"}
          />
        );
      })}
    </List>
  );

  const Placeholder = items !== undefined && items.length === 0 && (
    <div class="flex justify-center items-center column fill">
      <img
        src={"assets/images/no-data.svg"}
        alt={u("You do not have any data.")}
        style={"width:200px; height: 190px"}
      />
      <p>{placeholder ?? u("You do not have any data.")}</p>
    </div>
  );

  const SeeAll = onSeeAll !== undefined && (
    <a onClick={onSeeAll}>
      <p
        class={`m-0 p-16 uppercase ${border} square__see-all primary`}
      >{u`See all`}</p>
    </a>
  );

  return (
    <Card class={className}>
      <div class={`square__header radius`}>
        <p class={"p-16 m-0 bold square__header__p"}>{title}</p>
      </div>
      <div class="flex column justify-center fill relative">
        {ListJSX}
        {Placeholder}
        {SeeAll}
        {children}
      </div>
    </Card>
  );
};
