import { fetchy } from "polycraft/src/util/fetchy";
import { useCallback, useEffect, useState } from "preact/hooks";
import { VaultSchema } from "../../types";

let v: VaultSchema | undefined = undefined;

export function useVault() {
  const [capture, setCapture] = useState(false);
  const [vault, setVault] = useState<VaultSchema | undefined>(v);

  useEffect(() => {
    async function init() {
      setVault(undefined);
      const data = await fetchy(`/vault`, { method: "GET" });
      v = data;
      setVault(v);
    }

    if (!v) {
      init();
    }

    window.addEventListener("cashpoolrefresh", init);
    return () => window.removeEventListener("cashpoolrefresh", init);
  }, []);

  const onCapture = useCallback((): void => setCapture(true), []);

  const onCaptureClose = useCallback(() => setCapture(false), []);

  return {
    onCapture,
    onCaptureClose,
    state: {
      capture,
      vault,
    },
  };
}
