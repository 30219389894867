import { EFormEvent } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";
import { BankDetail } from "../capture-process/useCaptureProcess";

interface ConfirmValues extends Record<string, string | File | number> {
  PIN: string;
}

export function useCaptureConfirmDialog(
  bankDetail: BankDetail,
  onConfirm: () => void
) {
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const { PIN } = await check<ConfirmValues>(e);

      const { BANKID, SCURRENCY, CURRENCYD, SAMOUNT } = bankDetail;

      const params = {
        ACCOUNTID: BANKID,
        SAMOUNT,
        SCURRENCY,
        CURRENCYD: CURRENCYD,
        CURRENCYS: SCURRENCY,
        PIN,
      };

      const { BALANCE } = await fetchy("/capture", {
        body: JSON.stringify(params),
      });

      const detail = {
        SCURRENCY,
        BALANCES: BALANCE,
      };

      window.dispatchEvent(new CustomEvent("cashpoolrefresh"));

      onConfirm();
    },
    [onConfirm, bankDetail]
  );

  return {
    onInternalConfirm,
    onSubmit,
    button,
  };
}
