import { h } from "preact";

export function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61 83"
      version="1.1"
      class={"primary"}
      height={109}
      width={108}
    >
      <g
        id="Merchant"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Paying-PinCode-Blue"
          transform="translate(-150.000000, -98.000000)"
        >
          <g id="Lock" transform="translate(150.000000, 98.000000)">
            <path
              d="M47.1421,81.7372 L13.2831,81.7372 C10.7691,81.7372 8.7311,79.6992 8.7311,77.1852 L8.7311,52.7152 C8.7311,50.2012 10.7691,48.1642 13.2831,48.1642 L47.1421,48.1642 C49.6561,48.1642 51.6941,50.2012 51.6941,52.7152 L51.6941,77.1852 C51.6941,79.6992 49.6561,81.7372 47.1421,81.7372 Z"
              id="Stroke-3"
              stroke="currentColor"
              stroke-width="2.055"
            ></path>
            <path
              d="M33.3501,61.9262 C33.3501,59.9212 31.4681,58.3552 29.3731,58.8982 C28.3161,59.1722 27.4541,60.0402 27.1821,61.0982 C26.8081,62.5532 27.4541,63.8892 28.5501,64.5782 L27.3651,71.9242 C27.2861,72.4192 27.6671,72.8682 28.1701,72.8682 L32.2551,72.8682 C32.7571,72.8682 33.1401,72.4192 33.0601,71.9242 L31.8741,64.5782 C32.7581,64.0232 33.3501,63.0472 33.3501,61.9262 Z"
              id="Stroke-7"
              stroke="currentColor"
              stroke-width="2.055"
            ></path>
            <path
              d="M30.2124,20.5639 L30.2124,20.5639 C20.8964,20.5639 13.3444,28.1159 13.3444,37.4329 L13.3444,48.1639 L47.0814,48.1639 L47.0814,37.4329 C47.0814,28.1159 39.5284,20.5639 30.2124,20.5639 Z"
              id="Stroke-11"
              stroke="currentColor"
              stroke-width="2.055"
            ></path>
            <path
              d="M17.4527,48.164 L17.4527,37.433 C17.4527,30.397 23.1777,24.674 30.2127,24.674 C37.2477,24.674 42.9727,30.397 42.9727,37.433 L42.9727,48.164 L17.4527,48.164 Z"
              id="Stroke-15"
              stroke="currentColor"
              stroke-width="2.055"
            ></path>
            <line
              x1="30.2124"
              y1="10.5297"
              x2="30.2124"
              y2="-0.0003"
              id="Fill-17"
            ></line>
            <line
              x1="30.2124"
              y1="10.5297"
              x2="30.2124"
              y2="-0.0003"
              id="Stroke-19"
              stroke="currentColor"
              stroke-width="2.055"
            ></line>
            <line
              x1="51.9029"
              y1="20.0087"
              x2="59.3489"
              y2="12.5637"
              id="Fill-21"
            ></line>
            <line
              x1="51.9029"
              y1="20.0087"
              x2="59.3489"
              y2="12.5637"
              id="Stroke-23"
              stroke="currentColor"
              stroke-width="2.055"
            ></line>
            <line
              x1="8.1724"
              y1="19.1645"
              x2="0.7264"
              y2="11.7185"
              id="Fill-25"
            ></line>
            <line
              x1="8.1724"
              y1="19.1645"
              x2="0.7264"
              y2="11.7185"
              id="Stroke-27"
              stroke="currentColor"
              stroke-width="2.055"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}
