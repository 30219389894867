import { set } from "idb-keyval";
import { Json } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { useCallback, useEffect, useState } from "preact/hooks";

let image = "3D-Secure";

export function useLogin() {
  const [isChoosing, setIsChoosing] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    image = "3D-Secure";
  }, []);

  const onSelected = useCallback((i: string) => (image = i), []);

  const onSecurityImage = useCallback(async () => {
    setOpen(false);

    const body = JSON.stringify({ SIM: image });

    await fetchy("/sim", { body });

    await Promise.all([set("security", image)]);

    document.documentElement.style.overflowY = "auto";

    history.pushState({}, "", "/home.html");
  }, []);

  const onSuccess = useCallback(async (result: Json): Promise<void> => {
    if (result.SIM !== "NYET") {
      await Promise.all([set("security", result.SIM)]);
      history.pushState({}, "", "/home.html");
    }

    setIsChoosing(true);

    setOpen(true);
  }, []);

  return {
    onSelected,
    onSuccess,
    onSecurityImage,
    state: {
      isChoosing,
      open,
    },
  };
}
