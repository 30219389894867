import { useState } from "preact/hooks";
import { Bank, Capture, Commissions } from "../../types";

interface DashboardData {
  banks: Bank[] | undefined;
  captures: Capture[] | undefined;
  commissions: Commissions | undefined;
}

let d: DashboardData = {
  banks: undefined,
  commissions: undefined,
  captures: undefined,
};

export function useHome() {
  const [open, setOpen] = useState(false);

  return {
    onOpen: (b: boolean) => {
      setOpen(b);
    },
    state: {
      open,
    },
  };
}
