import { EFormEvent, ESelectEvent } from "polycraft/src/types";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef, useState } from "preact/hooks";
import { useRate } from "../../hooks/useRate";
import { Commission, currenciesOptions, Currency } from "../../types";

export interface ConvertValues extends Record<string, string | File | number> {}

function init(commission: Commission | undefined) {
  const filtered = currenciesOptions.filter(
    (p) => p.value !== commission?.currency
  );
  filtered.unshift({ label: "" as Currency, value: "" as Currency });
  return filtered;
}

export interface ConvertDialogProps {
  onClose: () => void;
  onConfirm: (values: ConvertValues) => void;
  commission: Commission | undefined;
}

export function useConvertDialog(
  commission: Commission | undefined,
  onConfirm: (values: ConvertValues) => void,
  onClose: () => void
) {
  const [currency, setCurrency] = useState<Currency | "">("");
  const [filtered] = useState(() => init(commission));
  const [rate, setRate] = useRate("CONVERT");
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onChange = useCallback(
    async (e: ESelectEvent) => {
      if (!commission) {
        return;
      }

      const { amount: b, currency: c } = commission;

      setCurrency(e.currentTarget.value as Currency);
      setRate(c, e.currentTarget.value, b, {});
    },
    [commission]
  );

  const onInternalClose = useCallback(() => {
    setCurrency("");
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const values = await check<ConvertValues>(e);
      onConfirm(values);
    },
    [onConfirm]
  );

  return {
    onInternalConfirm,
    onSubmit,
    onChange,
    onInternalClose,
    button,
    state: {
      filtered,
      currency,
      rate: rate ? rate.AMOUNT : "",
    },
  };
}
