import { EFormEvent, ESelectEvent } from "polycraft/src/types";
import { check } from "polycraft/src/util/form";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";

export interface ApexValues extends Record<string, string | number | File> {
  ORDERTYPE: "MARKET" | "LIMIT";
  MOM: number;
  PRICE: "AUTOMATIC" | number;
}

export function useApexDialog(
  values: ApexValues | undefined,
  onConfirm: (values: ApexValues) => void,
  onClose: () => void
) {
  const [type, setType] = useState<"MARKET" | "LIMIT">(
    values?.ORDERTYPE ?? "MARKET"
  );
  const button = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (values?.ORDERTYPE) {
      setType(values?.ORDERTYPE);
    }
  }, [values?.ORDERTYPE]);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onChange = useCallback(async (e: ESelectEvent) => {
    setType(e.currentTarget.value as "MARKET" | "LIMIT");
  }, []);

  const onInternalClose = useCallback(() => {
    setType("MARKET");
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const values = await check<ApexValues>(e);
      onConfirm({ ...values, ORDERTYPE: type });
    },
    [onConfirm, type]
  );

  return {
    onInternalConfirm,
    onSubmit,
    onChange,
    onInternalClose,
    button,
    state: {
      type,
    },
  };
}
