import { u } from "polycraft/src/ui/text";
import { Fragment, h } from "preact";
import { useState } from "preact/hooks";

interface Props {
  name?: string;
}

export function PinInput({ name = "PIN" }: Props) {
  const [visible, setVisible] = useState(false);

  function onClick() {
    setVisible((v) => !v);
  }

  return (
    <div class="relative">
      <label for="pin">{u("Pin")}</label>

      <input
        id="pin"
        name={name}
        type={visible ? "text" : "password"}
        pattern={"[0-9]{6}"}
        required={true}
        autocomplete={"off"}
      />

      {visible ? (
        <span
          class="material-icons-round pc-pinfield__svg absolute"
          onClick={onClick}
        >
          visibility
        </span>
      ) : (
        <span
          class="material-icons-round pc-pinfield__svg absolute"
          onClick={onClick}
        >
          visibility_off
        </span>
      )}
    </div>
  );
}
