import { u } from "polycraft/src/ui/text";
import { FunctionComponent, h } from "preact";
import { Square } from "../square";
import { useCaptureSquare } from "./useCaptureSquare";

interface Props {
  title: string;
  length: number;
  class?: string;
}

export const CaptureSquare: FunctionComponent<Props> = ({
  class: className,
  title,
  length,
}: Props) => {
  const { items, onSeeAll, hasMore, onMore } = useCaptureSquare(length);

  const More = hasMore && (
    <div class="flex justify-center mt-16">
      <a class="button mb-16 px-16 py-8 flex items-center" onClick={onMore}>
        <span>{u("More")}</span>
      </a>
    </div>
  );

  return (
    <Square
      items={items}
      title={title}
      onSeeAll={onSeeAll}
      class={`fill flex ${className} captures-square`}
    />
  );
};
