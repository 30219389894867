import { u } from "polycraft/src/ui/text";
import { h } from "preact";
import { Square } from "../square";
import { useBankSquare } from "./useBankSquare";

export const BankSquare = () => {
  const { items } = useBankSquare();

  return (
    <div class="flex fill">
      <Square
        items={items}
        title={u("Bank account")}
        placeholder={u(
          "Please contact our customer service to add another bank account."
        )}
        class={"fill min200h"}
      />
    </div>
  );
};
