import { EFormEvent } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";
import { ApexValues } from "../apex-dialog/useApexDialog";

interface ConfirmValues extends Record<string, string | File | number> {
  PIN: string;
}

export interface ApexDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  values: ApexValues | undefined;
}

export function useApexDialogConfirm({ values, onConfirm }: ApexDialogProps) {
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const { PIN } = await check<ConfirmValues>(e);

      if (!values) {
        return;
      }

      const body = JSON.stringify({
        ORDERTYPE: values.ORDERTYPE,
        MOM: values.MOM,
        PRICE: values?.PRICE.toString(),
        PIN,
      });

      const detail = await fetchy("/apex-add", { body });

      window.dispatchEvent(new CustomEvent("apexadd", { detail }));

      onConfirm();
    },
    [values, onConfirm]
  );

  return {
    onInternalConfirm,
    onSubmit,
    button,
  };
}
