import { fetchy } from "polycraft/src/util/fetchy";
import { useEffect, useState } from "preact/hooks";
import { Bank } from "../../types";

let initial: Bank[] | undefined = undefined;

export function useBanks() {
  const [banks, setBanks] = useState<Bank[] | undefined>(initial);

  useEffect(() => {
    async function init() {
      const data = await fetchy(
        `/banks`,
        { method: "GET" },
        { showProgress: false }
      );
      initial = data.result;

      setBanks(initial);
    }

    if (!initial) {
      init();
    }

    window.addEventListener("cashpoolrefresh", init);
    return () => window.removeEventListener("cashpoolrefresh", init);
  }, []);

  return banks;
}
