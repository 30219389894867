import { useCallback, useState } from "preact/hooks";
import { useBanks } from "../../hooks/useBanks";
import { useRate } from "../../hooks/useRate";
import { CaptureValues } from "../capture-dialog/useCaptureDialog";

export enum CaptureStep {
  Capture = 0,
  Review = 1,
  Confirm = 2,
}

interface State {
  step: CaptureStep;
}

export interface BankDetail extends Record<string, string | number> {
  BANKID: string;
  AMOUNT: number;
  SAMOUNT: number;
  RATESD: number;
  SCURRENCY: string;
  FEE: number;
  CURRENCYD: string;
}

export function useCaptureProcess(onClose: () => void) {
  const banks = useBanks();
  const [state, setState] = useState<State>({ step: CaptureStep.Capture });
  const [details, setDetails] = useState<BankDetail | null>(null);
  const [, setRate] = useRate("CAPTURE");

  const onConfirmation = useCallback(() => {
    setState((s) => ({ step: CaptureStep.Confirm }));
  }, []);

  const onCaptureStep = useCallback(() => {
    setState({ step: CaptureStep.Capture });
  }, []);

  const onBackToReview = useCallback(() => {
    setState((s) => ({ step: CaptureStep.Review }));
  }, []);

  const onRequest = useCallback(
    async (values: CaptureValues) => {
      const { AMOUNT, CURRENCY, BANK } = values;

      const bank = banks?.find((b) => b.ID === BANK);
      if (!bank) {
        return;
      }

      const amount = parseInt(AMOUNT, 10);
      const r = await setRate(CURRENCY, bank.DEVISE, amount, {});

      setDetails({
        BANKID: BANK,
        AMOUNT: amount,
        SAMOUNT: r.AMOUNT,
        RATESD: r.RATE,
        SCURRENCY: CURRENCY,
        CURRENCYD: bank.DEVISE,
        FEE: r.FEE ?? 0,
      });

      setState((s) => ({ ...s, step: CaptureStep.Review, max: amount }));
    },
    [banks, setRate, setDetails]
  );

  const onInternalClose = useCallback(() => {
    document.documentElement.style.overflowY = "auto";

    setDetails(null);
    setState({ step: CaptureStep.Capture });
    onClose();
  }, [onClose]);

  return {
    onConfirmation,
    onCaptureStep,
    onBackToReview,
    onRequest,
    onInternalClose,
    state: {
      ...state,
      details,
    },
  };
}
