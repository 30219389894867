import { u } from "polycraft/src/ui/text";
import { FunctionComponent, h } from "preact";
import { BankSquare } from "../../components/bank-square";
import { CaptureProcess } from "../../components/capture-process";
import { CaptureSquare } from "../../components/capture-square";
import { CommissionSquare } from "../../components/commission-square";
import { useHome } from "./useHome";

const Home: FunctionComponent = () => {
  const {
    onOpen,
    state: { open },
  } = useHome();

  return (
    <div class="flex column p-16 fill">
      <div class="flex justify-end">
        <a
          class="button mb-16 px-16 py-8 flex items-center"
          onClick={() => onOpen(true)}
        >
          <span class="material-icons-round mr-8">file_download</span>
          {u`Capture`}
        </a>
      </div>
      <div class="flex">
        <CaptureProcess open={open} onClose={() => onOpen(false)} />
        <CommissionSquare length={5} />
        <CaptureSquare
          title={u("Latest captures")}
          length={6}
          class={"ml-16"}
        />
      </div>
      <div class={"mt-16"}>
        <BankSquare />
      </div>
    </div>
  );
};

export default Home;
