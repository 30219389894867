import { useCallback, useState } from "preact/hooks";

export enum CaptureGoldStep {
  Capture = 0,
  Confirm = 1,
}

export interface CaptureGoldValues
  extends Record<string, string | File | number> {
  END: string;
  SAMOUNT: number;
  SCURRENCY: string;
}

interface State {
  values: CaptureGoldValues | null;
  step: CaptureGoldStep;
}

export function useCaptureGoldProgress(onClose: () => void) {
  const [state, setState] = useState<State>({
    step: CaptureGoldStep.Capture,
    values: null,
  });

  const onBack = () => {
    setState({ step: CaptureGoldStep.Capture, values: null });
  };

  const onCaptureConfirm = (values: CaptureGoldValues) => {
    setState({ step: CaptureGoldStep.Confirm, values });
  };

  const onInternalClose = useCallback(() => {
    document.documentElement.style.overflowY = "auto";
    setState({ step: CaptureGoldStep.Capture, values: null });
    onClose();
  }, [onClose]);

  return {
    onBack,
    onInternalClose,
    onCaptureConfirm,
    state,
  };
}
