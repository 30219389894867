import { Card } from "polycraft/src/ui/card";
import { Fragment, FunctionalComponent, h, JSX } from "preact";

interface Props {
  title: string;
  children?: JSX.Element;
}

export const RawSquare: FunctionalComponent<Props> = ({
  title,
  children,
}: Props) => {
  return (
    <Card class={"p-0"}>
      <Fragment>
        <p class={"bold m-0 p-16 raw-header radius white"}>{title}</p>
        {children}
      </Fragment>
    </Card>
  );
};
