import { useCallback } from "preact/hooks";
import { useCaptures } from "../../hooks/useCaptures";
import { Capture } from "../../types";

function transform({ SAMOUNT, SCURRENCY, DATES, TIMES }: Capture) {
  return {
    metaText: SAMOUNT?.toString(),
    description: `${DATES} ${TIMES}`,
    name: SCURRENCY,
  };
}

function getItems(captures: Capture[] | undefined) {
  if (!captures) {
    return undefined;
  }
  return captures.map(transform);
}

export function useCaptureSquare(length: number) {
  const { captures, hasMore, onMore } = useCaptures();
  const items = getItems(captures);

  const onGoToHistory = useCallback(() => {
    history.pushState({}, "", "/history.html");
  }, []);

  return {
    items,
    hasMore,
    onMore,
    onSeeAll: length < 0 ? onGoToHistory : undefined,
  };
}
