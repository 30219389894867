import { get } from "idb-keyval";
import { useCallback, useEffect, useState } from "preact/hooks";

export function useDashboardHeader() {
  const [security, setSecurity] = useState("");
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window === "undefined") {
      return "0";
    }

    const darkMode = window.localStorage.getItem("dark-mode");
    if (darkMode === "" || darkMode === null) {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    return darkMode === "1";
  });

  useEffect(() => {
    document.documentElement.style.transition = "background 0.5s";
  }, []);

  const onDarkModeClick = useCallback(() => {
    setDarkMode((dm) => {
      window.localStorage.setItem("dark-mode", dm ? "0" : "1");
      if (dm) {
        document.documentElement.classList.remove("dark");
      } else {
        document.documentElement.classList.add("dark");
      }

      return !dm;
    });
  }, []);

  useEffect(() => {
    async function retrieve() {
      const s = await get<string>("security");
      setSecurity(s ?? "");
    }
    retrieve();
  }, []);

  const onClick = async () => {
    window.dispatchEvent(new CustomEvent("cashpoolrefresh"));
  };

  return {
    onClick,
    onDarkModeClick,
    security,
    darkMode,
  };
}
