import { Fragment, h } from "preact";
import { Commission } from "../../types";
import { ConvertDialog } from "../convert-dialog";
import { ConvertDialogConfirm } from "../convert-dialog-confirm";
import { ConvertStep, useConvertProgress } from "./useConvertProgress";

interface ConvertProcessProps {
  commission: Commission | undefined;
  onClose: () => void;
}

export const ConvertProcess = ({
  commission,
  onClose,
}: ConvertProcessProps) => {
  const { state, onConvertConfirm, onBack, onInternalClose } =
    useConvertProgress(onClose);

  if (!commission) {
    return <Fragment></Fragment>;
  }

  if (state.step === ConvertStep.Confirm && state.values) {
    return (
      <ConvertDialogConfirm
        commission={commission}
        onClose={onBack}
        onConfirm={onInternalClose}
        values={state.values}
      />
    );
  }

  return (
    <ConvertDialog
      onClose={onInternalClose}
      commission={commission}
      onConfirm={onConvertConfirm}
    />
  );
};
