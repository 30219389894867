import { IconProps } from "polycraft/src/types";
import { FunctionalComponent } from "preact";

export interface SquareData {
  name: string;
  description: string;
  icon?: FunctionalComponent<IconProps>;
  disabled?: boolean;
  metaText?: string;
}

export type Currency =
  | "MYR"
  | "EUR"
  | "USD"
  | "GBP"
  | "XOF"
  | "NGN"
  | "GMD"
  | "AUD"
  | "IDR"
  | "SGD"
  | "EGP"
  | "SAR"
  | "AED"
  | "CAD"
  | "TWD"
  | "HKD"
  | "CNY"
  | "JPY";
export const Currencies: Currency[] = [
  "EUR",
  "USD",
  "GBP",
  "SAR",
  "MYR",
  "XOF",
  "NGN",
  "GMD",
  "AUD",
  "IDR",
  "SGD",
  "EGP",
  "AED",
  "CAD",
  "TWD",
  "HKD",
  "CNY",
  "JPY",
];

export interface Commission {
  amount: number;
  currency: Currency;
}

export interface Commissions {
  [k: string]: string;
}

export interface VaultSchema {
  IMOM: number;
  CEZG: number;
  MOMYIELD: number;
  CMOM: number;
  BUYMOM: number;
  SELLMON: number;
  COMEZG: number;
  ROI: number;
}

export type Bank = {
  ID: string;
  BANKN: string;
  PACCTID: number;
  IBAN: string;
  ACCTN: string;
  BANKA: string;
  ACCTH: string;
  DEVISE: string;
  IACCT: string;
};

export interface Banks {
  [k: string]: {
    BANKN: string;
    PACCTID: number;
    IBAN: string;
    ACCTN: string;
    BANKA: string;
    ACCTH: string;
    DEVISE: string;
    IACCT: string;
  };
}

export interface Capture {
  BANKID: string;
  SAMOUNT: number;
  SCURRENCY: Currency;
  RATESD: number;
  DATES: string;
  TIMES: string;
}

export const currenciesOptions = Currencies.map((c) => ({
  label: c,
  value: c,
}));

export interface DashboardData {
  banks: Bank[] | undefined;
  captures: Capture[] | undefined;
  commissions: Commissions | undefined;
}

export interface CommissionsData {
  banks: Bank[] | undefined;
  commissions: Commissions | undefined;
}

export interface AsideItems {
  name: string;
  icon: FunctionalComponent<IconProps>;
}
