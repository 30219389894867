import { EFormEvent } from "polycraft/src/types";
import { fetchy } from "polycraft/src/util/fetchy";
import { check } from "polycraft/src/util/form";
import { useCallback, useRef } from "preact/hooks";
import { Commission } from "../../types";
import { ConvertValues } from "../convert-dialog/useConvertDialog";

interface ConfirmValues extends Record<string, string | File | number> {
  PIN: string;
}

export interface ConvertDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  commission: Commission | undefined;
  values: ConvertValues;
}

export function useConvertDialogConfirm({
  values,
  onConfirm,
  commission,
}: ConvertDialogProps) {
  const button = useRef<HTMLButtonElement>(null);

  const onInternalConfirm = useCallback(() => {
    button.current?.click();
  }, [button]);

  const onSubmit = useCallback(
    async (e: EFormEvent) => {
      const { PIN } = await check<ConfirmValues>(e);

      if (!commission) {
        return;
      }

      const { SAMOUNT, CURRENCYD } = values;
      const SCURRENCY = commission.currency;

      const body = JSON.stringify({
        SAMOUNT,
        SCURRENCY,
        CURRENCYS: SCURRENCY,
        CURRENCYD,
        AMOUNTSD: values?.AMOUNTSD,
        PIN,
      });
      const { BALANCED, BALANCES } = await fetchy("/convert", { body });

      const detail = {
        SCURRENCY,
        CURRENCYD,
        BALANCES,
        BALANCED,
      };

      window.dispatchEvent(new CustomEvent("cashpoolrefresh", { detail }));

      onConfirm();
    },
    [commission, values, onConfirm]
  );

  return {
    onInternalConfirm,
    onSubmit,
    button,
  };
}
