import { u } from "polycraft/src/ui/text";
import { h } from "preact";
import { ApexCancelDialogConfirm } from "../../components/apex-cancel-dialog-confirm";
import { ApexDialog } from "../../components/apex-dialog";
import { ApexDialogConfirm } from "../../components/apex-dialog-confirm";
import { ApexStep, useApex } from "../../hooks/useApex";
import "./index.css";

h;

const icons: Record<"CANCELLED" | "EXECUTED" | "PROGRESS", string> = {
  CANCELLED: "unpublished",
  EXECUTED: "check_circle",
  PROGRESS: "cancel",
};

const max = 50;

export const Apex = () => {
  const {
    onCloseCancelDialog,
    onOpenCancelDialog,
    onOpen,
    onClose,
    onConfirm,
    onBack,
    onApexConfirm,
    onMore,
    onPrevious,
    onNext,
    state: { orderID, apex, count, total, orders, USC, index },
  } = useApex();

  console.info("orders", orders);

  const Placeholder = orders && orders.length === 0 && (
    <tr class="apex__table__tbody__tr">
      <td colSpan={9} class="text-center apex__table__tbody__td">
        {u("You don't have any APEX order")}
      </td>
    </tr>
  );

  const Orders =
    orders &&
    orders.length > 0 &&
    orders.map(
      ({ USC, ORDER, ORDERID, ORDERTYPE, MOM, PRICE, DATETIME, STATUS }) => {
        const last = DATETIME.indexOf(".", -1);
        const d = DATETIME.replace("T", " ").replace("Z", "").slice(0, last);

        return (
          <tr class="apex__table__tbody__tr">
            <td>{USC}</td>
            <td>{ORDER}</td>
            <td>{ORDERID}</td>
            <td>{ORDERTYPE}</td>
            <td>{MOM}</td>
            <td>{PRICE}</td>
            <td>{d}</td>
            <td>{STATUS}</td>
            <td class="text-center">
              <span
                style={"margin-top: 7px"}
                class={`material-icons-round status_${STATUS} ${
                  STATUS === "PROGRESS" ? "hover" : ""
                }`}
                onClick={
                  STATUS === "PROGRESS"
                    ? () => onOpenCancelDialog(ORDERID)
                    : undefined
                }
              >
                {icons[STATUS]}
              </span>
            </td>
          </tr>
        );
      }
    );

  const Dialog = apex.step === ApexStep.Apex && (
    <ApexDialog
      values={apex.values}
      USC={USC}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );

  const ConfrmDialog = apex.step === ApexStep.Pin && (
    <ApexDialogConfirm
      values={apex.values}
      onClose={onBack}
      onConfirm={onApexConfirm}
    />
  );

  const CancelDialog = orderID && (
    <ApexCancelDialogConfirm
      ORDERID={orderID}
      onClose={onCloseCancelDialog}
      onConfirm={onCloseCancelDialog}
    />
  );

  return (
    <div class="flex p-16 fill column fill">
      <div class="flex justify-end items-end column">
        <a class="button mb-16 px-16 py-8 flex items-center" onClick={onOpen}>
          <span class="material-icons-round mr-8">add</span>
          <span>{u("Create order")}</span>
        </a>
        <table
          class="fill w-100 bg-white box-shadow border-radius-4 apex__table"
          cellSpacing="0"
          cellPadding="16"
        >
          <thead>
            <tr class="apex__table__thead__tr">
              <th class="apex__table__thead__th">{u("USC")}</th>
              <th class="apex__table__thead__th">{u("ORDER")}</th>
              <th class="apex__table__thead__th">{u("ORDERID")}</th>
              <th class="apex__table__thead__th">{u("ORDERTYPE")}</th>
              <th class="apex__table__thead__th">{u("MOM")}</th>
              <th class="apex__table__thead__th">{u("PRICE")}</th>
              <th class="apex__table__thead__th">{u("DATETIME")}</th>
              <th class="apex__table__thead__th">{u("STATUS")}</th>
              <th class="apex__table__thead__th">{u("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {Placeholder}
            {Orders}
          </tbody>
          <tfoot>
            <tr>
              <th scope="col" colSpan={9}>
                <div class="flex justify-between">
                  <div></div>
                  <div class="flex items-center">
                    Results from {index * max} to{" "}
                    {index * max + (orders?.length ?? 0)}
                    <a
                      href={index === 0 ? undefined : ""}
                      role="button"
                      class={"outline flex mx-8 table__pagination__a mx-8"}
                      onClick={onPrevious}
                    >
                      <span class="material-icons-round">chevron_left</span>
                    </a>
                    {index + 1}
                    <a
                      href={count > total ? "" : undefined}
                      role="button"
                      class={"outline flex mx-8 table__pagination__a mx-8"}
                      onClick={onNext}
                    >
                      <span class="material-icons-round">chevron_right</span>
                    </a>
                  </div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        {Dialog}
        {ConfrmDialog}
        {CancelDialog}
      </div>
    </div>
  );
};
