import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { h } from "preact";
import { ApexValues, useApexDialog } from "./useApexDialog";

export interface ApexDialogProps {
  onClose: () => void;
  onConfirm: (values: ApexValues) => void;
  USC: string;
  values?: ApexValues;
}

export const ApexDialog = ({
  USC,
  onClose,
  onConfirm,
  values,
}: ApexDialogProps) => {
  const {
    onChange,
    onSubmit,
    onInternalConfirm,
    button,
    state: { type },
  } = useApexDialog(values, onConfirm, onClose);

  const orders = [
    {
      label: u("Market"),
      value: "MARKET",
    },
    {
      label: u("Limit"),
      value: "LIMIT",
    },
  ];

  return (
    <Dialog
      onClose={onClose}
      onConfirm={onInternalConfirm}
      title={u("Convert")}
      class={"pc-swal__input"}
      confirm={u("Confirm")}
      show={true}
    >
      <form onSubmit={onSubmit} class="mt-16">
        <label for="USC">{u("USC")}</label>
        <input name="USC" id="USC" value={USC} readonly={true} />

        <label for="ORDER">{u("Order")}</label>
        <input name="ORDER" id="ORDER" value={"SELL"} readonly={true} />

        <label for="ORDERTYPE">{u("Order type")}</label>
        <select
          id="ORDERTYPE"
          name="ORDERTYPE"
          required={true}
          value={values?.ORDERTYPE}
          onChange={onChange}
        >
          {orders.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>

        <label for="MOM">{u("Number of MOM to sell")}</label>
        <input name="MOM" id="MOM" value={values?.MOM} type="number" required />

        <label for="PRICE">{u("Price")}</label>
        <input
          name="PRICE"
          id="PRICE"
          type={type === "LIMIT" ? "number" : "string"}
          readonly={type === "MARKET"}
          value={type === "LIMIT" ? values?.PRICE : "AUTOMATIC"}
          required
        />

        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
