import { u } from "polycraft/src/ui/text";
import { FunctionComponent, h } from "preact";

export const Call: FunctionComponent = () => {
  return (
    <div class="flex wrap column">
      <div class="flex">
        <div class={"m-16"}>
          <p class={"primary"}>{u`Asia Branch`}</p>
          <p>{u`Tel : (+60) 345 34 678`}</p>
          <p>{u`Fax : (+60) 345 34 679`}</p>
        </div>
        <div class={"m-16"}>
          <p class={"primary"}>{u`Europe Branch`}</p>
          <p>{u`Tel : (+60) 345 34 678`}</p>
          <p>{u`Fax : (+60) 345 34 679`}</p>
        </div>
      </div>
      <div class="flex">
        <div class={"m-16"}>
          <p class={"primary"}>{u`Main Branch`}</p>
          <p>{u`Tel : (+60) 345 34 678`}</p>
          <p>{u`Fax : (+60) 345 34 679`}</p>
        </div>
        <div class={"m-16"}>
          <p class={"primary"}>{u`Mena Branch`}</p>
          <p>{u`Tel : (+60) 345 34 678`}</p>
          <p>{u`Fax : (+60) 345 34 679`}</p>
        </div>
      </div>
    </div>
  );
};
