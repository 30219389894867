import { h } from "preact";
import "./style.css";
import { AsideProps, useAside } from "./useAside";

export const Aside = (props: AsideProps) => {
  const { generateCss, onClick, labels } = useAside(props);
  const { items } = props;

  return (
    <aside class={"ui-aside"}>
      <div class={"items"}>
        <div class="flex justify-center">
          <img
            src={"assets/icons/singleton-icon.svg"}
            width={75}
            height={35.5}
            alt={"Singleton"}
            class={"fill-secondary mb-32"}
          />
        </div>

        {items.map((item, index) => {
          const css =
            generateCss(index) + `mb-24 hover flex items-center items-center`;
          const Icon = item.icon;

          return (
            <div key={item} class={css} onClick={(e) => onClick(e, index)}>
              <Icon />
              <p class={"ml-16 my-0 partial-margin-desktop white"}>
                {labels[item.name as keyof typeof labels]}
              </p>
            </div>
          );
        })}
      </div>
    </aside>
  );
};
