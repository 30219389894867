import { Dialog } from "polycraft/src/components/dialog";
import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionalComponent, h } from "preact";
import { VaultSchema } from "../../types";
import { CaptureGoldValues } from "../capture-gold-progress/useCaptureGoldProgress";
import { useCaptureGoldDialog } from "./useCaptureGoldDialog";

interface Props {
  onClose: () => void;
  onConfirm: (values: CaptureGoldValues) => void;
  vault: VaultSchema | undefined;
}

export const CaptureGoldDialog: FunctionalComponent<Props> = ({
  onClose,
  onConfirm,
  vault,
}: Props) => {
  const { onInternalConfirm, button, onSubmit } = useCaptureGoldDialog(
    onClose,
    onConfirm
  );

  return (
    <Dialog
      onClose={onClose}
      onConfirm={onInternalConfirm}
      title={u("Capture")}
      class={"pc-swal__input"}
      confirm={u("Confirm")}
      show={true}
    >
      <form onSubmit={onSubmit} class="mt-16">
        <label for="end">{u("Phone")}</label>
        <input name="END" required={true} type="tel" id="end" />

        <label for="amount">{u("Amount")}</label>
        <input
          name="SAMOUNT"
          required={true}
          max={vault?.CEZG}
          id="amount"
          type="number"
        />

        <button
          ref={button}
          style={"border:none; background: none; padding: 0; min-height: 0"}
        />
      </form>
    </Dialog>
  );
};
