import { fetchy } from "polycraft/src/util/fetchy";
import { useCallback, useEffect, useState } from "preact/hooks";
import { Capture } from "../../types";

let c: Capture[] | undefined = undefined;

interface State {
  captures: Capture[] | undefined;
  hasMore: boolean;
}

const MAX_ITEMS = 10;
let loaded = 0;

export function useCaptures() {
  const [state, setState] = useState<State>({
    captures: c,
    hasMore: !c ? false : c.length >= MAX_ITEMS,
  });

  useEffect(() => {
    async function init() {
      const data = await fetchy(
        "/captures",
        { method: "GET" },
        { showProgress: false }
      );
      c = data.result;

      if (c) {
        loaded = c.length;
      }

      setState({
        captures: c,
        hasMore: !c ? false : Object.keys(c).length >= MAX_ITEMS,
      });
    }

    if (!c) {
      init();
    }

    window.addEventListener("cashpoolrefresh", init);

    return () => window.removeEventListener("cashpoolrefresh", init);
  }, []);

  const onMore = useCallback(async () => {
    const json = await fetchy("/captures", {
      body: JSON.stringify({ start: loaded, stop: loaded + MAX_ITEMS }),
      method: "POST",
    });
    setState({
      captures: json,
      hasMore: !json ? false : Object.keys(json).length >= MAX_ITEMS,
    });
  }, []);

  return { onMore, ...state };
}
