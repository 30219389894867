import AppContainer from "polycraft/src/components/app-container";
import { Dialog } from "polycraft/src/components/dialog";
import { useMediaQuery } from "polycraft/src/hooks/useMediaQuery";
import { FetchConfiguration } from "polycraft/src/util/fetchy";
import { Fragment, FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Apex } from "../../routes/apex";
import Captures from "../../routes/captures";
import Currencies from "../../routes/currencies";
import Faq from "../../routes/faq";
import Help from "../../routes/help";
import Home from "../../routes/home";
import Login from "../../routes/login";
import { Vault } from "../../routes/vault";
import { Aside } from "../aside";
import { DashboardHeader } from "../dashboard-header";

declare const PREACT_APP_BASE_URL: string;

const private_components: {
  [k: string]: FunctionalComponent;
} = {
  "/": Login,
  "/currencies.html": Currencies,
  "/faq.html": Faq,
  "/help.html": Help,
  "/login.html": Login,
  "/history.html": Captures,
  "/home.html": Home,
  "/vault.html": Vault,
  "/apex.html": Apex,
  "/logout.html": Login,
};

export const components = {};

const items = [
  {
    name: "home.html",
    icon: () => <i class="material-icons-round md-24">home</i>,
  },
  {
    name: "currencies.html",
    icon: () => <i class="material-icons-round md-24">paid</i>,
  },
  {
    name: "vault.html",
    icon: () => <i class="material-icons-round md-24">lock</i>,
  },
  {
    name: "apex.html",
    icon: () => <span class="material-icons-round md-24">stream</span>,
  },
  {
    name: "history.html",
    icon: () => <i class="material-icons-round md-24">history</i>,
  },
  {
    name: "help.html",
    icon: () => <i class="material-icons-round md-24">help</i>,
  },
  {
    name: "faq.html",
    icon: () => <i class="material-icons-round md-24">quiz</i>,
  },
];

const App: preact.FunctionalComponent = () => {
  const mobile = useMediaQuery();
  const [url, setUrl] = useState("");

  useEffect(() => {
    const onPopState = (e: Event) => {
      let pathname = (e.currentTarget as Window).location.pathname;
      if (PREACT_APP_BASE_URL !== "/") {
        pathname = pathname.replace(PREACT_APP_BASE_URL, "");
      }

      setUrl(pathname);
    };

    let pathname = window.location.pathname;
    if (PREACT_APP_BASE_URL !== "/") {
      pathname = pathname.replace(PREACT_APP_BASE_URL, "");
    }

    setUrl(pathname);

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  useEffect(() => {
    FetchConfiguration.unauthorized = async () => {
      window.location.href = "/";
      return;
    };
  }, []);

  const connected = ["", "/", "/login.html"].indexOf(url) === -1;
  const C = private_components[url];

  const Element = connected ? (
    <Fragment>
      <div class="flex fill">
        <Aside items={items} url={url} />
        <div class="flex column fill">
          <DashboardHeader url={url} />
          <main>{C && <C />}</main>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>{C && <C />}</Fragment>
  );

  return (
    <AppContainer>
      {mobile === false ? (
        <Fragment>{Element}</Fragment>
      ) : (
        <div class="flex justify-center items-center fill">
          <div class="flex column items-center justify-center p-16 mb-0">
            <h1 class="not-found__h1 mb-0" style="font-size: 120px">
              401
            </h1>
            <p class="text-center mb-8 bold">Oops</p>
            <p class="text-center m-0">
              You cannot see the dashboard on mobile
            </p>
          </div>
        </div>
      )}
      <Dialog global={true} />
    </AppContainer>
  );
};

export default App;
