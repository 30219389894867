import { useState } from "preact/hooks";

export const avatars = [
  "3D-Secure",
  "Alert",
  "Browser-Eye",
  "Browser-Protection",
  "Browser-Search",
  "Bug-Shield",
  "Chip-Shield",
  "Cloud-Lock",
  "Document-Protection",
  "Fingerprint",
  "Firewall",
  "House-Lock",
];

export function useAvatars(onSelected: (s: string) => void) {
  const [selected, setSelected] = useState(avatars[0]);

  const onInternalSelected = (s: string) => {
    setSelected(s);
    onSelected(s);
  };

  return {
    selected,
    onInternalSelected,
  };
}
