import { useState } from "preact/hooks";

export type HelpTab = "email" | "chat" | "call";

export function useHelp() {
  const [tab, setTab] = useState<HelpTab>("email");

  return {
    setTab,
    state: {
      tab,
      email: tab === "email" ? "help help--active" : "help",
      chat: tab === "chat" ? "help help--active" : "help",
      call: tab === "call" ? "help help--active" : "help",
    },
  };
}
