import { u } from "polycraft/src/ui/text";
import { FunctionalComponent, h } from "preact";
import { CaptureSquare } from "../../components/capture-square";

const Captures: FunctionalComponent = () => {
  return (
    <div class={"p-16 flex items-start fill"}>
      <CaptureSquare length={0} title={u("Captures")} />
    </div>
  );
};

export default Captures;
