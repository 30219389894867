import { FunctionComponent, h } from "preact";
import "./style.css";

interface Props {
  message: string;
  description?: string;
  onClick?: () => void;
  type: "primary" | "secondary";
}

export const Circle: FunctionComponent<Props> = ({
  message,
  description,
  onClick,
  type,
}: Props) => {
  const css = type === "primary" ? "ui-circle-primary" : "ui-circle-secondary";

  return (
    <div
      class={`flex justify-center items-center p-16 box-shadow text-center ui-circle ${css}`}
      onClick={onClick}
    >
      <p>{message}</p>
      <p class={"ui-circle-description"}>{description}</p>
    </div>
  );
};
