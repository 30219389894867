import { u } from "polycraft/src/ui/text";
import { Fragment, FunctionalComponent, h } from "preact";
import { ConvertProcess } from "../convert-progress";
import { Square } from "../square";
import { useCommissionSquare } from "./useCommissionSquare";

interface Props {
  length: number | undefined;
  class?: string;
}

const MAX = 99999999;

export const CommissionSquare: FunctionalComponent<Props> = ({
  length = MAX,
  class: css = "fill",
}: Props) => {
  const {
    onClick,
    onSeeAll,
    onClose,
    onMore,
    state: { items, commission, hasMore },
  } = useCommissionSquare(length);

  const More = hasMore && (
    <div class="flex justify-center mt-16">
      <a class="button mb-16 px-16 py-8 flex items-center" onClick={onMore}>
        <span>{u("More")}</span>
      </a>
    </div>
  );

  return (
    <Fragment>
      <div class={`flex ${css}`}>
        <Square
          items={items}
          onClick={onClick}
          title={u("Commission list")}
          onSeeAll={length !== MAX ? onSeeAll : undefined}
          class={"fill commissions-square"}
          length={length}
        />
        <ConvertProcess commission={commission} onClose={onClose} />
      </div>
      {More}
    </Fragment>
  );
};
