import { fetchy } from "polycraft/src/util/fetchy";
import { useCallback, useEffect, useState } from "preact/hooks";
import { ApexValues } from "../../components/apex-dialog/useApexDialog";

interface Order {
  USC: string;
  ORDER: string;
  ORDERID: string;
  ORDERTYPE: string;
  MOM: number;
  PRICE: string;
  STATUS: "CANCELLED" | "EXECUTED" | "PROGRESS";
  DATETIME: string;
}

export enum ApexStep {
  Apex = 0,
  Pin = 1,
}

interface State {
  orders: Order[] | null;
  USC: string;
  count: number;
  total: number;
}

interface ApexState {
  step: ApexStep | null;
  values?: ApexValues;
}

const MAX_ITEMS = 50;

let items: Order[] = [];

export function useApex() {
  const [apex, setApex] = useState<ApexState>({
    step: null,
    values: undefined,
  });
  const [index, setIndex] = useState(0);
  const [state, setState] = useState<State>({
    orders: null,
    USC: "",
    count: 0,
    total: 0,
  });
  const [orderID, setOrderID] = useState<string | null>(null);

  const onOpenCancelDialog = useCallback((id: string) => {
    setOrderID(id);
  }, []);

  const onCloseCancelDialog = useCallback(() => {
    setOrderID(null);
  }, []);

  const onConfirm = useCallback((values: ApexValues) => {
    setApex({ step: ApexStep.Pin, values });
  }, []);

  const onApexConfirm = useCallback(() => {
    setApex({ step: null });
  }, []);

  const onOpen = useCallback(() => {
    setApex({ step: ApexStep.Apex });
  }, []);

  const onBack = useCallback(() => {
    setApex((s) => ({ ...s, step: ApexStep.Apex }));
  }, []);

  const onClose = useCallback(() => {
    setApex({ step: null, values: undefined });
  }, []);

  const onMore = useCallback(async () => {
    const json = await fetchy(
      `/apex?start=${index * MAX_ITEMS}&max=${index * MAX_ITEMS + MAX_ITEMS}`,
      {
        method: "GET",
      }
    );
    setState({ ...json });
  }, [index]);

  useEffect(() => {
    const onAdd = (e: Event) => {
      const ce = e as CustomEvent;
      const order = ce.detail;
      setState((s) => ({ ...s, orders: [order, ...(s.orders ?? [])] }));
    };

    window.addEventListener("apexadd", onAdd);
    return () => {
      window.removeEventListener("apexadd", onAdd);
    };
  }, []);

  useEffect(() => {
    const onCancel = (e: Event) => {
      const ce = e as CustomEvent;
      const id = ce.detail;
      setState((s) => ({
        ...s,
        orders: (s.orders ?? []).map((order) =>
          order.ORDERID === id ? { ...order, STATUS: "CANCELLED" } : order
        ),
      }));
    };

    window.addEventListener("apexcancel", onCancel);
    return () => {
      window.removeEventListener("apexcancel", onCancel);
    };
  }, []);

  useEffect(() => {
    onMore();

    const onCashPoolRefresh = () => {
      setIndex(0);
      onMore();
    };

    window.addEventListener("cashpoolrefresh", onCashPoolRefresh);

    return () => {
      window.removeEventListener("cashpoolrefresh", onCashPoolRefresh);
    };
  }, [onMore]);

  const onPrevious = useCallback((e: Event) => {
    e.preventDefault();
    setIndex((i) => (i === 0 ? 0 : i - 1));
  }, []);

  const onNext = useCallback((e: Event) => {
    e.preventDefault();

    setIndex((i) => i + 1);
  }, []);

  return {
    onOpenCancelDialog,
    onCloseCancelDialog,
    onMore,
    onOpen,
    onClose,
    onBack,
    onConfirm,
    onApexConfirm,
    onPrevious,
    onNext,
    state: { orderID, ...state, apex, index },
  };
}
